import React from 'react';

import { groupBy } from 'ramda';
import { useSetRecoilState } from 'recoil';
import { Flex } from 'reflexbox';

import { Text } from 'components/text/text';
import { SUBMISSION_NOTES_SECTIONS_MENU } from 'constants/submission-notes';
import { submissionNotesState } from 'store/submission-notes';
import { isContextualSidebarOpenState } from 'store/ui';

import { DisplayChange } from './display-change';

export const bySection = groupBy((change: any & { path: string[] }) => {
    return change.path[0];
});

const ChangeLogSections = {
    transaction: SUBMISSION_NOTES_SECTIONS_MENU[0],
    subjectProperty: SUBMISSION_NOTES_SECTIONS_MENU[1],
    mortgage: SUBMISSION_NOTES_SECTIONS_MENU[2],
    credit: SUBMISSION_NOTES_SECTIONS_MENU[3],
    mortgageProtectionInsurance: SUBMISSION_NOTES_SECTIONS_MENU[4],
    lawyer: SUBMISSION_NOTES_SECTIONS_MENU[5],
    whyNotNestoLending: SUBMISSION_NOTES_SECTIONS_MENU[6],
    importantDetails: SUBMISSION_NOTES_SECTIONS_MENU[7],
};

export const ChangeLog = ({ changes }) => {
    const sectionsChanges = bySection(changes);
    const setSectionSelected = useSetRecoilState(submissionNotesState(null));
    const setIsContextualSidebarOpen = useSetRecoilState(
        isContextualSidebarOpenState
    );

    return (
        <Flex flexDirection="column" alignItems="center">
            {changes &&
                Object.keys(sectionsChanges).map((changes) => {
                    const clickableChange =
                        changes !== 'applicationType' && changes !== 'product';

                    return (
                        <Flex
                            flexDirection="column"
                            alignItems="center"
                            key={`${changes}-change-log`}
                            css={{
                                cursor: clickableChange ? 'pointer' : 'default',
                            }}
                            onClick={() => {
                                if (clickableChange) {
                                    setSectionSelected(
                                        ChangeLogSections[changes]
                                    );
                                    setIsContextualSidebarOpen(false);
                                }
                            }}
                        >
                            <Text
                                tx={changes}
                                css={{
                                    marginBottom: '10px',
                                    marginTop: '10px',
                                    fontSize: '18px',
                                    fontWeight: '600',
                                }}
                            />
                            {sectionsChanges[changes].map((change) => (
                                <DisplayChange
                                    key={`${change.type}-${change.to}`}
                                    change={change}
                                />
                            ))}
                        </Flex>
                    );
                })}
        </Flex>
    );
};
